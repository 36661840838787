import { useLazyLogConnectionQuery } from '@services/user/userApi';
import { ServiceLoader } from '@sgme/ui';
import { BdrProvider } from 'BdrContext';
import { router } from 'pages/router';
import { type PropsWithChildren, useEffect } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { HelpCenter } from './components/HelpCenter';
import { CrashModal } from './components/modals/CrashModal';
import { TimeoutModal } from './components/modals/TimeoutModal/TimeoutModal';
import { IntlProvider } from './features/intl/IntlProvider';
import { MatomoProvider } from './features/matomo/MatomoProvider';
import { store } from './store/store';

export const App = (): JSX.Element => {
  return (
    <MatomoProvider>
      <Provider store={store}>
        <ConnectionLogger>
          <IntlProvider>
            <BdrProvider>
              <ServiceLoader>
                <CrashModal />

                <TimeoutModal />

                <HelpCenter />

                <RouterProvider router={router} />
              </ServiceLoader>
            </BdrProvider>
          </IntlProvider>
        </ConnectionLogger>
      </Provider>
    </MatomoProvider>
  );
};

/**
 * call /api/user/connect when the application is opened
 */
const ConnectionLogger = ({ children }: PropsWithChildren) => {
  const [connect] = useLazyLogConnectionQuery();

  useEffect(() => {
    connect();
  }, [connect]);

  return children;
};
