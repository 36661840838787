import type { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  env: string;
  sgconnect: ISGWTConnectConfiguration;
  apiUrl: string;
  redirect_uri: string;
  matomo: {
    url: string;
    siteid: string;
  };
  widgets: {
    localDevelopment: boolean;
    environment: 'development' | 'production';
  };
  sgwtFooter: {
    accessibilityCompliance: 'none' | 'partial' | 'full';
    accessibilityLink: string;
  };
  sgbt: {
    version: string;
  };
}

export function getAppConfig(): AppConfig {
  const config = window.sgmeConfiguration;
  const apiUrl = (import.meta.env.VITE_APP_API_URL ?? config.apiUrl).replace(/\/$/, '');
  return {
    ...config,
    apiUrl,
  };
}

export function isProductionEnvironment(): boolean {
  return getAppConfig().env === 'production';
}

export function isDevEnvironment(): boolean {
  const env = getAppConfig().env;
  return env === 'local' || env === 'devci';
}
