import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import type { UserAccess } from "../../../../services/fx/model/models";

interface UserAccesIconProps {
  userAccess: UserAccess;
}

type AccessIconProps = {
  color: string;
  icon?: string;
  tooltipLocaleKey?: string;
};

const getAccessIconProps = (userAccess: UserAccess): AccessIconProps => {
  switch (userAccess) {
    case 'unauthorized': {
      return {
        color: 'warning',
        icon: 'group_off',
        tooltipLocaleKey: 'fx.useraccess.unauthorized',
      };
    }
    case 'authorized_on_my_desk_clients': {
      return {
        color: 'primary',
        icon: 'group',
        tooltipLocaleKey: 'fx.useraccess.authorized_on_my_desk_clients',
      };
    }
    case 'authorized_read_only': {
      return {
        color: 'secondary',
        icon: 'visibility',
        tooltipLocaleKey: 'fx.useraccess.authorized_read_only',
      };
    }
    case 'authorized_on_all_clients':
    case 'authorized_on_my_clients':
    case 'default':
    default: {
      return {
        color: 'primary',
      };
    }
  }
};

export const UserAccessIcon = ({ userAccess }: UserAccesIconProps): JSX.Element => {
  const { color, icon, tooltipLocaleKey } = getAccessIconProps(userAccess);

  return (
    <div className="badge rounded-pill" data-e2e={userAccess}>
      {icon && (
        <>
          <i className={`icon icon-md px-4 text-${color}`} id="userAccessIcon">
            {icon}
            <UncontrolledTooltip
              data-toggle="tooltip"
              placement="bottom"
              target="userAccessIcon"
              className={`tooltip-${color}`}
            >
              <FormattedMessage id={tooltipLocaleKey} />
            </UncontrolledTooltip>
          </i>
        </>
      )}
    </div>
  );
};
