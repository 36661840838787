import type { Middleware } from '@reduxjs/toolkit';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import { EVENT_AUTH_EXPIRED } from '@sgwt/connect-core';
import type {AppState} from "../store";
import {actionCreators} from "../actions";

export const sgwtMiddleware =
  (sgwt: SGWTConnectCore): Middleware<object, AppState> =>
  (store) => {
    sgwt.on(EVENT_AUTH_EXPIRED, () => store.dispatch(actionCreators.ui.authExpired()));
    return (next) => (action) => next(action);
  };
