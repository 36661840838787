import { createInstance } from '@datapunt/matomo-tracker-react';

import { getAppConfig } from 'config';

import type { MatomoEvent } from "./matomo.model";

export const matomo = (function setupMatomo() {
  const { matomo: matomoConfig } = getAppConfig();

  return createInstance({
    urlBase: matomoConfig.url,
    siteId: Number.parseInt(matomoConfig.siteid, 10),
    trackerUrl: `${matomoConfig.url}/piwik.php`,
    srcUrl: `${matomoConfig.url}/piwik.js`,
    // cf https://github.com/Amsterdam/matomo-tracker/tree/main/packages/react#spa-link-tracking
    linkTracking: false,
  });
})();

export const trackEvent = (event: MatomoEvent) => {
  matomo.trackEvent(event)
}

interface SgFooterWidget extends Element {
  getUserConsentOnMatomo(): Promise<boolean>;
}

function getFooterWidget(): SgFooterWidget | null {
  return document.querySelector('sgwt-mini-footer');
}

export function addUserConsentSettingsCallback(callback: () => void) {
  const widget = getFooterWidget();
  if (!widget) {
    return;
  }
  widget.addEventListener('sgwt-mini-footer--current-user-consent', callback);
  widget.addEventListener('sgwt-mini-footer--user-consent-changed', callback);
}

export function removeUserConsentSettingsCallback(callback: () => void) {
  const widget = getFooterWidget();
  if (!widget) {
    return;
  }
  widget.removeEventListener('sgwt-mini-footer--current-user-consent', callback);
  widget.removeEventListener('sgwt-mini-footer--user-consent-changed', callback);
}

export function getUserConsentOnMatomo() {
  const widget = getFooterWidget();
  if (widget && widget.getUserConsentOnMatomo) {
    return widget.getUserConsentOnMatomo();
  }
  return Promise.resolve(false);
}
