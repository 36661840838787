import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { isDefined, isNotDefined } from '@sgme/fp';
import { AuthorizeFxClient } from 'pages/fx/components/AuthorizeFxClient';
import { LoadingMessage } from 'components/LoadingMessage';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import { FX_PRODUCTS_ROUTE } from '../constants';
import { CancelValidationHandler } from '@components/CancelValidationHandler';
import { Authorize } from '@features/authorization/Authorize';
import { PageTracker } from '@features/matomo/PageTracker';
import type { FxOrderProductKey, FxOrderProfileColumns } from '@services/fx/model/order';
import { type FxCashProfileColumns, isFxCashProductKey } from '@services/fx/model/cash';
import { CashGrid } from '../FxProductsPage/CashTab/CashGrid';
import { type GridCashProfileRow, toGridRow } from '../FxProductsPage/CashTab/useData';
import FxEditProfileSubHeader from './FxEditProfileSubHeader';
import { useGetFXProfileMarginGridQuery } from '@services/fx/getFXProfileMarginGrid';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { cancelRowEditing, initRows } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { FxCashTieringEditor } from './FxProfileEditor/FxCashTieringEditor';
import { FxEditProfileTableActionHeader } from '@pages/fx/FxEditProfilePage/FxEditProfileTableActionHeader';
import { FxCashMarginEditor } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashMarginEditor';
import type { AgGridReact } from '@ag-grid-community/react';
import { getEditingPermissionsForCurrentProduct } from '@features/authorization/getEditingPermissionsForCurrentProduct';
import type { FxProfileMarginGrid, FxProfileMarginGridRow } from '@services/fx/model/profile-margin-grid';
import { toOrderGridRow } from '@pages/fx/utils/rows';
import OrderGrid from '@pages/fx/components/FxOrderProfileTable/OrderGrid';
import { FxOrderEditor } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { useGetFxProductChanges } from '@hooks/useGetFxProductChange';

function FxEditProfilePage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientBdrLevel, clientBdrId, perimeterKey, productKey, gridDataType, profileId } =
    useFxSelectAndEditProfileUrlParams();

  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);
  useDocumentTitle(
    `Edit - ${productKey} - ${client?.clientLongName} (${clientBdrLevel})> - Margin and Tiering - SG Markets`,
  );

  const { data: editedProfile } = useGetFXProfileMarginGridQuery({
    perimeterKey,
    productKey,
    profileId,
    gridDataType,
  });

  // CASH LOGIC
  const isCashProduct = isFxCashProductKey(productKey);

  useEffect(() => {
    if (isDefined(editedProfile)) {
      dispatch(initRows({ rows: editedProfile.rows, shouldKeepEditingRow: false }));
    }
  }, [dispatch, editedProfile]);

  const rowByUiId = useAppSelector((state) => state.fxProfileEdition.rowByUiId);

  const profileRows = Object.values(rowByUiId);
  const nonDeletedRows = profileRows.filter((row) => row.state !== 'deleted');

  const { haveRowsChanged, hasRowInEdition } = useGetFxProductChanges();
  const cashRows: GridCashProfileRow[] =
    isDefined(editedProfile) && isCashProduct
      ? (nonDeletedRows as FxProfileMarginGridRow<FxCashProfileColumns>[]).map(
          toGridRow(
            productKey,
            gridDataType!,
            editedProfile as FxProfileMarginGrid<FxCashProfileColumns>,
            false,
            undefined,
          ),
        )
      : [];

  const orderRows =
    perimeterKey === 'fx-order-perimeter' && isDefined(editedProfile)
      ? toOrderGridRow(nonDeletedRows as FxProfileMarginGridRow<FxOrderProfileColumns>[])
      : [];

  const gridRef = useRef<AgGridReact>(null);

  const onFilter = useCallback((query: string) => {
    if (isNotDefined(gridRef.current?.api)) {
      return;
    }
    gridRef.current?.api.setQuickFilter(query);
  }, []);

  if (isNotDefined(editedProfile)) {
    return <LoadingMessage />;
  }

  const allowedPermissions = getEditingPermissionsForCurrentProduct(gridDataType, productKey, perimeterKey);

  return (
    <Authorize
      authorizedFor={allowedPermissions}
      redirectTo={generatePath(FX_PRODUCTS_ROUTE, {
        clientBdrId: String(clientBdrId),
        clientBdrLevel,
        perimeterKey,
      })}
    >
      <AuthorizeFxClient>
        <PageTracker>
          <div className="col w-100">
            <div className="d-flex flex-row h-100">
              <div className="col mt-4 px-4">
                <div className="d-flex flex-column h-100">
                  <CancelValidationHandler hasUserChanges={haveRowsChanged || hasRowInEdition}>
                    <button
                      type="button"
                      className="btn btn-flat-secondary d-flex align-items-center align-self-start mb-2"
                      onClick={() => {
                        dispatch(cancelRowEditing());
                        navigate(-1);
                      }}
                    >
                      <FormattedMessage id="back.to.presets.list" />
                    </button>
                  </CancelValidationHandler>

                  <FxEditProfileSubHeader profile={editedProfile} gridDataType={gridDataType} />

                  <FxEditProfileTableActionHeader
                    onFilter={onFilter}
                    gridDataType={gridDataType}
                    perimeterKey={perimeterKey}
                    productKey={productKey}
                  />
                  {perimeterKey === 'fx-order-perimeter' ? (
                    <OrderGrid
                      productKey={productKey as FxOrderProductKey}
                      maxRowDisplayed={17}
                      rows={orderRows}
                      ref={gridRef}
                      isEditing
                    />
                  ) : (
                    <CashGrid
                      ref={gridRef}
                      rows={cashRows as GridCashProfileRow[]}
                      isGroupedByInstrument={false}
                      isEditing
                    />
                  )}
                </div>
              </div>

              {/*<FxProfileEditor editedRowId={editedRowId} />*/}
              {gridDataType === 'margin-grid-data-type' && <FxCashMarginEditor />}

              {gridDataType === 'tiering-grid-data-type' && <FxCashTieringEditor />}

              {perimeterKey === 'fx-order-perimeter' && <FxOrderEditor />}
            </div>
          </div>
        </PageTracker>
      </AuthorizeFxClient>
    </Authorize>
  );
}

export default FxEditProfilePage;
