import ClientsMargins from "./ClientsMargins";
import {Authorize} from "../../../features/authorization/Authorize";
import {PageTracker} from "../../../features/matomo/PageTracker";


function LagClientsPage(): JSX.Element {
  return (
    <Authorize authorizedFor={['LAG_READ']} redirectTo="/">
      <PageTracker>
        <ClientsMargins />
      </PageTracker>
    </Authorize>
  );
}

export default LagClientsPage;
