import { generatePath } from 'react-router-dom';
import { isDefined, isNotDefined } from '@sgme/fp';
import { AuthorizeFxClient } from 'pages/fx/components/AuthorizeFxClient';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import { FX_PRODUCTS_ROUTE } from '../constants';
import { Authorize } from '@features/authorization/Authorize';
import { PageTracker } from '@features/matomo/PageTracker';
import { type FxCashProductKey, type FxCashProfile, type FxCashProfileRow, isFxCashProductKey } from '@services/fx/model/cash';
import { CashGrid } from '../FxProductsPage/CashTab/CashGrid';
import { type GridCashProfileRow, toGridRow } from '../FxProductsPage/CashTab/useData';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import FxSelectedProfileActions from './FxSelectProfileHeader/FxSelectedProfileActions';
import { FxSelectableProfiles } from './FxSelectableProfiles';
import { useGetFXProfileMarginGridQuery } from '@services/fx/getFXProfileMarginGrid';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { type ChangeEvent, type ReactNode, useRef } from 'react';
import type { AgGridReact } from '@ag-grid-community/react';
import { getEditingPermissionsForCurrentProduct } from '@features/authorization/getEditingPermissionsForCurrentProduct';
import type { FxOrderProductKey, FxOrderProfileV2 } from '@services/fx/model/order';
import OrderGrid from '@pages/fx/components/FxOrderProfileTable/OrderGrid';
import FxSelectProfileTableActionHeader from '@pages/fx/FxSelectProfilePage/FxSelectProfileTableActionHeader';
import { toOrderGridRow } from '@pages/fx/utils/rows';

function Index(): ReactNode {
  const { productKey, gridDataType, perimeterKey, clientBdrLevel, clientBdrId, isInherited, profileId } =
    useFxSelectAndEditProfileUrlParams();
  const gridRef = useRef<AgGridReact>(null);

  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);
  useDocumentTitle(
    `Select - ${productKey} - ${client?.clientLongName} (${client?.clientBdrLevel})> - Margin and Tiering - SG Markets`,
  );

  const { data: selectedProfile } = useGetFXProfileMarginGridQuery(
    isDefined(profileId)
      ? {
          perimeterKey,
          productKey,
          profileId,
          gridDataType,
        }
      : skipToken,
  );

  if (!isDefined(selectedProfile)) return null;

  const orderRows =
    perimeterKey === 'fx-order-perimeter' ? toOrderGridRow((selectedProfile as FxOrderProfileV2).rows) : [];

  // CASH LOGIC
  const isCashProduct = isFxCashProductKey(productKey);

  const cashRows = isCashProduct
    ? (selectedProfile?.rows as FxCashProfileRow[]).map(
        toGridRow(productKey as FxCashProductKey, gridDataType!, selectedProfile as FxCashProfile, false, undefined),
      )
    : [];

  const onFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNotDefined(gridRef.current?.api)) {
      return;
    }
    gridRef.current?.api.setQuickFilter(event.target.value);
  };

  const allowedPermissions = getEditingPermissionsForCurrentProduct(gridDataType, productKey, perimeterKey);

  return (
    <Authorize
      authorizedFor={allowedPermissions}
      redirectTo={generatePath(FX_PRODUCTS_ROUTE, {
        clientBdrId: String(clientBdrId),
        clientBdrLevel,
        perimeterKey,
      })}
    >
      <AuthorizeFxClient>
        <PageTracker>
          <div className="col w-100">
            <div className="d-flex flex-row h-100">
              <FxSelectableProfiles />

              <div className="col mt-4 px-4">
                <div className="d-flex flex-column h-100">
                  <FxSelectedProfileActions selectedProfile={selectedProfile} isInheritedProfile={isInherited} />

                  <FxSelectProfileTableActionHeader productKey={productKey} onFilter={onFilter} />

                  {perimeterKey === 'fx-cash-rfq-perimeter' && (
                    <CashGrid ref={gridRef} rows={cashRows as GridCashProfileRow[]} isGroupedByInstrument={false} />
                  )}

                  {perimeterKey === 'fx-order-perimeter' && (
                    <OrderGrid
                      productKey={productKey as FxOrderProductKey}
                      maxRowDisplayed={10}
                      rows={orderRows}
                      ref={gridRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageTracker>
      </AuthorizeFxClient>
    </Authorize>
  );
}

export default Index;
