import type { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';

import { enableLogger } from './enableLogger';
import type { SgConnectUserInfo } from './sgwtConnect.model';
import type {Permission, Resource} from "../authorization/authorization.model";
import {sgwtConnect} from "./sgwtConnect";

const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
const TOPIC_SGCONNECT_READY = 'sgwt-connect--ready';

type Subscribers = {
  onUserInfo: (userInfo: SgConnectUserInfo<Resource, Permission> | undefined) => void;
};

const subscribeToWidgetBus = ({ onUserInfo }: Subscribers) => {
  if (onUserInfo) {
    // save user permissions in redux store
    window.SGWTWidgetConfiguration.bus.subscribe(BUS_TOPIC_SGCONNECT_USERINFO, onUserInfo);
  }
};

type SgwtConnect = HTMLElement & { setSgwtConnectInstance: (sgwtConnect: SGWTConnectCore) => void };

const setupWidget = () => {
  const widgetInstance = document.querySelector<SgwtConnect>('sgwt-connect');

  if (widgetInstance) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widgetInstance.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widgetInstance.setSgwtConnectInstance(sgwtConnect);
        // Remove the event listener
        widgetInstance.removeEventListener(TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
      };

      widgetInstance.addEventListener(TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widgetInstance.setSgwtConnectInstance(sgwtConnect);
    }
  }
};

export const requestAuthorization = (
  onAuthorizedSuccess: () => void,
  onAuthorizationError: (authorizationError: SGWTConnectError) => void,
  subscribers: Subscribers,
): void => {
  if (sgwtConnect.isAuthorized()) {
    // the user is authorized! Do what you want for identified user access...

    // set up the widget of sgwtConnect
    setupWidget();
    if (window.SGWTWidgetConfiguration) {
      // add subscriber to the event bus
      subscribeToWidgetBus(subscribers);
    }

    enableLogger(sgwtConnect);

    onAuthorizedSuccess();
  } else {
    // otherwise, an error occured when retrieve authorization
    const authorizationError = sgwtConnect.getAuthorizationError();

    if (authorizationError) {
      // the user tried to request authorization, but something wrong happened...
      onAuthorizationError(authorizationError);
    } else {
      // the user has not requested authorization. Do what you want for anonymous access...
      sgwtConnect.requestAuthorization();
    }
  }
};
