import { useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isDefined } from '@sgme/fp';
import type { LagClient, PostClientPayload, UpdateClientPayload } from 'services/clients';
import { usePostClientMutation, useUpdateClientsMutation } from 'services/clients';

import { FormMessageStatus } from 'components/Form/FormMessageStatus';
import { LoadingButton } from 'components/LoadingButton';

import { OverflowableModalBody } from '../OverflowableModalBody';

import { AccountPicker } from './AccountPicker';
import { Maturities } from './Maturities';
import { SelectChangeReason } from './SelectChangeReason';
import { SelectMarginProfile } from './SelectMarginProfile';
import { SelectPcruMargin } from './SelectPcruMargin';
import {Checkbox} from "../../Form/Checkbox";

export type DisplayMode = 'add' | 'edit' | 'addExisting';

const saveButtonLabel: Record<DisplayMode, { localeKey: string; icon: string }> = {
  add: { localeKey: 'common.add', icon: 'add' },
  edit: { localeKey: 'common.edit', icon: 'save' },
  addExisting: { localeKey: 'common.edit', icon: 'save' },
};

type ClientForm = UpdateClientPayload | PostClientPayload;

interface ClientFormModalProps extends ModalProps {
  client?: LagClient;
}

export const ClientFormModal = ({ client, ...props }: ClientFormModalProps): JSX.Element => {
  const { trackEvent } = useMatomo();
  const [postClient, { isLoading: postLoading }] = usePostClientMutation();
  const [updateClients, { isLoading: updateLoading }] = useUpdateClientsMutation();
  const isLoading = postLoading || updateLoading;

  const [currentClient, setCurrentClient] = useState<LagClient | undefined>(client);

  const displayMode = useMemo<DisplayMode>(() => {
    if (isDefined(client)) {
      trackEvent({ category: 'Client modal', action: 'Mode changed', name: 'edit' });
      return 'edit';
    }

    if (isDefined(currentClient)) {
      trackEvent({ category: 'Client modal', action: 'Mode changed', name: 'addExisting' });
      return 'addExisting';
    }
    trackEvent({ category: 'Client modal', action: 'Mode changed', name: 'add' });
    return 'add';
  }, [client, currentClient, trackEvent]);

  const methods = useForm<ClientForm>({
    mode: 'onChange',
    defaultValues: currentClient,
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const salesMarginProfileId = watch('salesMarginProfileId');

  const needChangeReason =
    isDefined(currentClient) && salesMarginProfileId !== currentClient?.salesMarginProfileId;

  const onChangeClient = (clientName: string) => {
    setValue('name', clientName)
  }

  const onFindExistingClient =
    (existingClient: LagClient) => {
      setCurrentClient(existingClient);
       // we fill the form with all the value of the client
      Object.entries(existingClient).forEach(([key, value]) => {
        setValue(key as keyof ClientForm, value);
      });
    }

  const innerOnClosed = () => {
    trackEvent({ category: 'Client modal', action: 'Closed' });

    if (props.onClosed) {
      props.onClosed();
    }
  };

  const innerOnOpened = () => {
    const name = displayMode.charAt(0).toUpperCase() + displayMode.slice(1);
    trackEvent({ category: 'Client modal', action: 'Closed', name });
  };

  const onSubmit: SubmitHandler<ClientForm> = async (clientForm) => {
    if (isDefined(currentClient)) {
      const { id } = currentClient;
      const updateClientPayload: UpdateClientPayload = { ...clientForm, id };
      updateClients([updateClientPayload]);
    } else {
      const postClientPayload: PostClientPayload = { ...clientForm, isActive: true };
      const clientFormSubmitReceived = await postClient(postClientPayload);
      if (clientFormSubmitReceived) {
        trackEvent({ category: 'Client modal', action: 'Saved' });
      }
    }

    innerOnClosed();
  };

  return (
    <Modal
      {...props}
      onOpened={innerOnOpened}
      onClosed={innerOnClosed}
      data-e2e="client-form-modal"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
          <ModalHeader tag="h3" close={props.modalHeaderClose}>
            <FormattedMessage id={`clientForm.title.${displayMode}`} />
          </ModalHeader>

          <OverflowableModalBody>
            <div>
              <label className="fw-medium mb-2">
                <FormattedMessage id="clientForm.clientName" />
              </label>

              {['edit', 'addExisting'].includes(displayMode) && (
                <>
                  <span
                    data-e2e="client-name"
                    className="form-control-plaintext fs-16 fw-bold"
                  >
                    {currentClient?.name}
                  </span>

                  {displayMode === 'addExisting' && (
                    <p className="py-3 text-info">
                      <i className="icon icon-sm me-2">info_outline</i>
                      <FormattedMessage id="clientForm.clientAlreadyAdded" />
                    </p>
                  )}
                </>
              )}

              {displayMode === 'add' && (
                <AccountPicker onFindExistingClient={onFindExistingClient} onChangeClient={onChangeClient} />
              )}
            </div>

            <Maturities />
            <SelectMarginProfile type="Sales" />
            <SelectMarginProfile type="DevL" />
            <SelectPcruMargin />

            {/* If we are updating and sales margin profile was changed, the user needs to provide a reason */}
            {needChangeReason && <SelectChangeReason />}

            {displayMode === 'edit' && (
              <Checkbox name="isActive" labelLocaleKey="clientForm.activeClient" className="mt-3" />
            )}
          </OverflowableModalBody>

          <ModalFooter className="d-flex justify-items-end">
            <FormMessageStatus
              name="clientForm"
              errorType="required"
              icon="error"
              status="warning"
              show={Object.keys(errors).length > 0}
            />

            <button className="btn btn-lg btn-flat-secondary me-2" onClick={innerOnClosed}>
              <FormattedMessage id="common.cancel" />
            </button>

            <LoadingButton
              type="submit"
              className="btn btn-lg btn-primary"
              icon={saveButtonLabel[displayMode].icon}
              loading={isLoading}
              disabled={Object.keys(errors).length > 0}
              dataE2e={`button-${displayMode}`}
            >
              <FormattedMessage id={saveButtonLabel[displayMode].localeKey} />
            </LoadingButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};
