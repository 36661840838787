import { LogBuilder } from '@sgme/logger';
import type { SGWTConnectCore } from '@sgwt/connect-core';

import { getAppConfig } from 'config';

import { sgwtConnect } from './sgwtConnect';

const isNode = typeof process === 'object' && !!process.versions && !!process.versions.node;

let logBuilder = LogBuilder();

if (!isNode) {
  if (import.meta.env.NODE_ENV === 'production') {
    logBuilder = logBuilder
      .enableConsole({ minLevel: 'WARN' })
      .enableServerLogs({
        storeName: 'main',
        url: `${getAppConfig().apiUrl}api/log`,
        prefix: '[Margin]',
      })
      .setAuthTokenFactory(() => {
        if (sgwtConnect.isAuthorized()) {
          const authToken = sgwtConnect.getAuthorizationHeader();
          if (authToken) return authToken;
        }
        throw new Error('No authorization header or token. Disconnected ?!');
      });
  } else {
    logBuilder = logBuilder.enableConsole({ minLevel: 'INFO' });
  }
}

const logger = logBuilder.build();

export const enableLogger = (sgwtConnect: SGWTConnectCore) => {
  sgwtConnect.on('authorizationDiscarded', logger.pauseServerLogs);
  sgwtConnect.on('authorizationExpired', logger.pauseServerLogs);
  sgwtConnect.on('renewAuthorizationError', logger.pauseServerLogs);
  sgwtConnect.on('renewAuthorizationSuccess', logger.resumeServerLogs);
};
