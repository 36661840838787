import { getAppConfig, isProductionEnvironment } from "../../../config";
import { useContext, useEffect, useRef } from "react";
import type { SgwtAccountCenterEvent, SgwtAccountCenterHTMLElement } from "../../../typings/sgwt-widgets";
import { LocaleContext, type SupportedLocale } from "../../../features/intl/IntlProvider";

// https://developer.fr.world.socgen/docs/web/widgets/structural-widgets/sgwt-account-center.html

export const AccountCenter = () => {
  const { locale, saveLocale } = useContext(LocaleContext);

  const ref = useRef<SgwtAccountCenterHTMLElement>(null);

  useEffect(() => {
    const listener = (event: Event) => {
      const locale = (event as SgwtAccountCenterEvent).detail.language
      saveLocale(locale as SupportedLocale)
    }

    ref.current?.addEventListener('sgwt-account-center--language-changed', listener)

    return () => {
      ref.current?.removeEventListener('sgwt-account-center--language-changed', listener)
    }
  }, [ ref.current ]);


  return (
    <sgwt-account-center
      ref={ref}
      authentication="sg-connect-v2"
      available-languages="en,fr"
      environment={isProductionEnvironment() ? "" : getAppConfig().env}
      language={locale}

      mode="sg-markets"
      // navigate-as={bool(props.navigateAs)}
      // navigate-as-user={json(props.navigateAsUser)} //  string; // json extends {name: string;}
      // navigate-as-list={json(props.navigateAsList)} // ?: string; // json extends Array<{name: string;}>
      // user={json(props.user)} // ?: string; // json extends {name: string;}
      // show-notification={bool(props.showNotification)}
    />

  )
}
