
import { LAG_ROUTE } from "../contants";
import MarginsPresets from "./MarginsPresets";
import {Authorize} from "../../../features/authorization/Authorize";
import {PageTracker} from "../../../features/matomo/PageTracker";

function LagPresetsPage(): JSX.Element {
  return (
    <Authorize authorizedFor={['LAG_MARGINS_READPRESETS']} redirectTo={LAG_ROUTE}>
      <PageTracker>
        <MarginsPresets />
      </PageTracker>
    </Authorize>
  );
}

export default LagPresetsPage;
