import type { PropsWithChildren } from 'react';
import { MatomoProvider as MatomoTrackerReact, useMatomo } from '@datapunt/matomo-tracker-react';

import { matomo } from './matomo';

export const MatomoProvider = (props: PropsWithChildren): JSX.Element => {
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  return <MatomoTrackerReact value={matomo} {...props} />;
};
