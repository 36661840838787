import { useCallback } from 'react';
import { useRef } from 'react';
import { isNonEmpty } from '@sgme/fp';
import type { SgwtHelpCenterHTMLElement } from "../typings/sgwt-widgets";

interface HelpCenterProps {
  className?: string;
  withHandler?: boolean;
  topic?: string;
}

export function HelpCenter({
  className = '',
  withHandler = false,
  topic,
}: HelpCenterProps): JSX.Element {
  const innerRef = useRef<SgwtHelpCenterHTMLElement>(null);

  const onClickHandler = useCallback(() => {
    if (isNonEmpty(topic)) {
      innerRef.current?.topic?.(topic);
    } else {
      innerRef.current?.open?.();
    }
  }, []);

  return (
    <>
      {
        withHandler && (
          <button
            className={`btn btn-flat-primary btn-icon ${className ?? ""}`}
            onClick={onClickHandler}
            onMouseUp={() => innerRef.current?.close?.()}
          >
            <em className="icon">help_outline</em>
          </button>
        )
      }

      <aside className="text-primary">
        <sgwt-help-center
          ref={innerRef}
          sg-connect-support="sg-connect-v2"
          application-id="sgmarkets-wmc"
          mail-subject="[SGWT Help Center] - [WMC] - User message"
        />
      </aside>
    </>
  );
}
