import { FxClients } from './FxClients';

import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import {Authorize} from "../../../features/authorization/Authorize";
import {PageTracker} from "../../../features/matomo/PageTracker";

function FxClientsPage(): JSX.Element {
  useDocumentTitle("Clients - Margin and Tiering - SG Markets")

  return (
    <Authorize authorizedFor={['FX_READALLDESK', 'FX_READMYDESK']} redirectTo="/">
      <PageTracker>
        <div className="flex-column p-4 col offset-lg-1 col-lg-10">
          <FxClients />
        </div>
      </PageTracker>
    </Authorize>
  );
}

export default FxClientsPage;
