import type { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import type React from 'react';

declare global {
  /** Upgrade JSX namespace to allow usage of SGWT Widgets */
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': Partial<{
        ref: React.Ref<SgwtAccountCenterHTMLElement>;
        authentication: string;
        'available-languages': string; // string[];
        debug: string; // boolean;
        environment: string;
        'hide-current-language': string; // boolean;
        'hide-services-link': string; // boolean;
        i18n: string; // object;
        language: string;
        mode: 'sg-markets';
        'navigate-as': string; // boolean;
        'navigate-as-user': string; // object;
        'navigate-as-list': string; // object;
        'show-languages-selection': string; // boolean;
        'show-personalisation': string; // boolean;
        'show-sign-in-button': string; // boolean;
        user: string; //  object;
        'user-dropdown-links': string; // object;
      }>;

      'sgwt-connect': Partial<{
        ref: React.Ref<SgwtConnectHTMLElement>;
        'client-id': string;
        scope: string;
        'acr-values': 'L1' | 'L2' | 'L3' | 'L4';
        'authorization-endpoint': string;
        debug: string; // boolean;
        'redirect-uri': string;
        'response-type': string;
      }>;

      'sgwt-help-center': Partial<{
        ref: React.Ref<SgwtHelpCenterHTMLElement>;
        'introduction-tour': string; // boolean;
        'sg-connect-support': 'sg-connect-v1' | 'sg-connect-v2';
        'email-endpoint': string;
        'default-send-to': string;
        'additional-message-information': string; // object;
        'message-topics': string; // object[];
        language: string;
        i18n: string; // object;
        user: string; // object;
        knowledge: string; // object;
        'knowledge-urls	': string; // string[];
        'application-id': string;
        'message-only': string; // boolean;
        'handle-visible': string; // boolean;
        'conversation-id': string;
        'mail-subject': string;
        debug: string; // boolean;
        'no-console': string; // boolean;
      }>;

      'sgwt-mini-footer': Partial<{
        ref: (element: HTMLElement | null) => void;
        id: string;
        about: string;
        'contact-us': string; // object;
        'contact-us-by-help-center': string;
        container: 'container';
        design: 'light' | 'dark' | 'black' | 'alt';
        i18n: string; // object;
        'legal-notices': string; // object;
        'logo-link': string;
        'logo-link-title': string;
        mode: 'sg-markets';
        type: 'compact' | 'medium';
      }>;
    }
  }
}

// HTMLElements definitions for SGWT Widgets...

export interface SgwtConnectHTMLElement extends HTMLElement {
  sgwtConnect: SGWTConnectCore;
  requestAuthorization: (cbSuccess: () => void, cbError: (err: SGWTConnectError) => void) => void;
  discardAuthorization: () => void;
  setSgwtConnectInstance: (sgwtConnect: SGWTConnectCore) => void;
}

export interface SgwtAccountCenterHTMLElement extends HTMLElement {
  changeLanguage: (language: string) => void;
  setUser: (user: object) => void;
  setNavigateAsUser: (user: object) => void;
  signOut: () => void;
  stopNavigationAs: () => void;
  showNavigateAs: () => void;
}

export type SgwtAccountCenterEvent = Event & {
  detail: {
    language?: LanguageIsoCode;
    // user?: NavigateAsUser;
  };
};

// import type { LanguageIsoCode } from 'locales';
export type LanguageIsoCode = string;

// export interface NavigateAsUser {
//   name: string;
//   company: string;
//   permissions?: Permission[];
// }
//
// export type Permission =
//   | 'ALL_INTERNAL_DESKS_VISIBILITY'
//   | 'CLIENT_FULL_VISIBILITY'
//   | 'DISCOVER_PAGE'
//   | 'EXTERNAL_API'
//   | 'INTERNAL_USER'
//   | 'MISSED_DEALS_FEEDBACK'
//   | 'PRIMARY_MARKET_QUOTE_ONLY'
//   | 'PRIMARY_MARKET_TRADE'
//   | 'PRODUCT_MODIFICATION'
//   | 'SECONDARY_MARKET_QUOTE_ONLY'
//   | 'SECONDARY_MARKET_TRADE'
//   | 'TRADE_ALLOCATION'
//   | 'TRADING_DESK_OVERRIDE'
//   | 'TRADING_RULES_BYPASS'
//   | 'HAS_BACK_OFFICE_ROLE'
//   | 'HAS_INTERNAL_APPLICATION_SUPPORT_ROLE'
//   | 'HAS_INTERNAL_SALES_ROLE'
//   | 'HAS_INTERNAL_TRADER_ROLE'
//   | 'HAS_IT_ROLE'
//   | 'HAS_TRADER_ROLE';

export interface SgwtHelpCenterHTMLElement extends HTMLElement {
  open: () => void;
  category: (categoryId: string) => void;
  topic: (topicId: string) => void;
  search: (value: string, categoryId?: string) => void;
  form: () => void;
  chat: () => void;
  close: () => void;
  setIntroductionTour: (introductionTour: boolean) => void;
  setEmailEndpoint: (emailEndpoint: string) => void;
  setDefaultSendTo: (sendTo: string) => void;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setAdditionalMessageInformation: (additionalMessageInformation?: { [key: string]: any }) => void;
  setMessageTopics: (messageTopics: object[]) => void;
  setLanguage: (language: string) => void;
  setI18n: (i18n: object) => void;
  setUser: (user: object) => void;
  setKnowledge: (knowledge: object) => void;
  setKnowledgeUrls: (knowledgeUrls: string[]) => void;
  setApplicationId: (applicationId: string) => void;
  setMessageOnly: (messageOnly: boolean) => void;
  setHandleVisible: (handleVisible: boolean) => void;
  setMailSubject: (mailSubject: string | null) => void;
}

export interface SgwtMiniFooterHTMLElement extends HTMLElement {
  // No API
}

// To get user info from widget

type BusEventCallback<T> = (payload: T | undefined) => void;
// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
type SubscriptionHandle = void;

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined | null;

  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;

  unsubscribe(handle: SubscriptionHandle): void;
}
export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = window.SGWTWidgetConfiguration;
  return widgetConfiguration?.bus ? widgetConfiguration.bus : null;
}

const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
  }
  return undefined;
}
