import { type PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
  addUserConsentSettingsCallback,
  getUserConsentOnMatomo,
  removeUserConsentSettingsCallback,
} from './matomo';

export function PageTracker({ children }: PropsWithChildren<object>): JSX.Element {
  const { trackPageView, pushInstruction } = useMatomo();
  const location = useLocation();
  const locationOrigin = window.location.origin;

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    function updateConsent() {
      getUserConsentOnMatomo().then((consent) => {
        // Documentation: https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/advanced-usage/cookies-consent.html
        pushInstruction('deleteCustomDimension', 1);
        if (consent) {
          trackPageView({ href: `${locationOrigin}${location.pathname}` });
          pushInstruction('rememberCookieConsentGiven', 4320);
          pushInstruction('setCustomDimension', 10, true);
        } else {
          pushInstruction('resetUserId');
          trackPageView({ href: `${locationOrigin}${location.pathname}` });
          pushInstruction('forgetCookieConsentGiven');
          pushInstruction('setCustomDimension', 10, false);
        }
      });
    }
    updateConsent();
    pushInstruction('forgetCookieConsentGiven');
    pushInstruction('requireCookieConsent');
    trackPageView({ href: `${locationOrigin}${location.pathname}` });
    addUserConsentSettingsCallback(updateConsent);
    return removeUserConsentSettingsCallback(updateConsent);
  }, [trackPageView, pushInstruction, location, locationOrigin]);

  return <>{children}</>;
}
