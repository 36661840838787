import { IntegratedHeader } from '@pages/layout/Header/IntegratedHeader';
import { Outlet } from 'react-router-dom';
import { Footer } from '@pages/layout/Footer';

export function LayoutWithIntegratedHeader(): JSX.Element {
  return (
    <div className="d-flex flex-column vh-100 container-fluid p-0" data-e2e="margin-app">
      <IntegratedHeader />
      <div className="flex-grow-1 d-flex flex-column">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
