import type { GridOptions } from "@ag-grid-community/core";
import type { ColDef } from "@ag-grid-community/core/dist/esm/es6/entities/colDef";
import type { MarginsTableRow } from "./LagClientsPage/ClientsMargins/ClientsMarginsTable/useRowData";

export const LAG_PATH = "/lag";
export const LAG_ROUTE = `${LAG_PATH}`;
export const LAG_PRESETS_PATH = "presets";
export const LAG_PRESETS_ROUTE = `${LAG_ROUTE}/${LAG_PRESETS_PATH}`;

// from @sg-bootstrap/ag-grid-theme -> agThemeSgBootstrapCondensed
const agThemeSgBootstrapCondensed = {
  headerHeight: 32,
  rowHeight: 24,
};

export const maturityClass = 'maturity';
export const maturityHeaderClass = 'maturity-header';
export const leftGroupHeaderClass = 'left-group-header';
export const rightGroupHeaderClass = 'right-group-header';
export const topHeaderClasses = [ 'h6', 'fw-bold' ];

export const lagDefaultColDef: ColDef<MarginsTableRow> = { enableRowGroup: true };

export const lagGridOptions: GridOptions = {
  ...agThemeSgBootstrapCondensed,
  suppressAggFuncInHeader: true,
  suppressContextMenu: true,
  suppressMovableColumns: true,
  stopEditingWhenCellsLoseFocus: true,
  groupDisplayType: 'custom',
  suppressRowClickSelection: true,

};
